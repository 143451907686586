interface HeroSubheadingProps {
    data: string;
}

const heroSubheading: React.FC<HeroSubheadingProps> = ({ data }) => {
    return (
        <div className="heroSubheading">
        <h2>{data || 'Default Subheading'}</h2>
        </div>
    );
}

export default heroSubheading;