import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import axios from 'axios';


import ThankYouPage from './components/ThankYouPage';
import HomePage from './components/HomePage';
import GTMBody from './components/gtmBody';
import GTMHead from './components/gtmHead';

interface Data {
  heroHeading?: string;
  heroSubheading?: string;
  heroParagraph?: string;
  heroImage?: string;
  featureTopHeading?: string;
  featureTopSubheading?: string;
  featureHeading1?: string;
  featureSubheading1: string;
  featureHeading2?: string;
  featureSubheading2?: string;
  featureHeading3?: string;
  featureSubheading3?: string;
  featureImage?: string;
  testimonialQuote1?: string;
  testimonialName1?: string;
  testimonialTitle1?: string;
  testimonialLocation1?: string;
  testimonialImage1?: string;
  testimonialQuote2?: string;
  testimonialName2?: string;
  testimonialTitle2?: string;
  testimonialLocation2?: string;
  testimonialImage2?: string;
  leadFormHeading?: string;
  leadFormParagraph?: string;
  leadFormButton?: string;
  companyName?: string;
  footerText?: string;
  logoImage?: string;
  logoTagline?: string;
}

interface ThankYouData {
  heroHeading: string;
  heroParagraph: string;
  heroButton: string;
  heroImageUrl: string;
  outBoundLink: string;

}

function App() {
  const [data, setData] = useState<Data | null>(null); // No need for 'any' since we're in JS file
  const [tyData, setThankYouData] = useState<ThankYouData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');


  // Use effect for Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Set homepaged content endpoint
        const homeEndpoint = "https://micrositeapi.postx3.com/content/6605f669818a3be628fbc08e";
        const homeResponse = await axios.get(homeEndpoint);
        console.log("Home Response")
        console.log(homeResponse.data);
        setData(homeResponse.data);

        setLoading(false); // Set loading to false once data is fetched

      } catch (error) {
        console.error('Error fetching data: ', error);
        setError('Error fetching data'); // Set an error message
        setLoading(false);
      }
    };
    fetchData(); // Call the function
  }
    , []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Set thank you page content endpoint
        const tyEndpoint = "https://micrositeapi.postx3.com/content/65fedd9c8ee13e47a200fca1";
        const tyResponse = await axios.get(tyEndpoint);
        console.log("Thank You Response")
        console.log(tyResponse.data);
        setThankYouData(tyResponse.data);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching data: ', error);
        setError('Error fetching data'); // Set an error message
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Or some loading component
  }

  if (error) {
    return <div>{error}</div>; // Display error message
  }

  // Unpack the data to match the ThankYouPageProps interface
  const thankYouData = {
    data: {
      heroHeading: tyData?.heroHeading ?? '',
      heroParagraph: tyData?.heroParagraph ?? '',
      heroButton: tyData?.heroButton ?? '',
      heroImageUrl: tyData?.heroImageUrl ?? '',
      outBoundLink: tyData?.outBoundLink ?? ''
    }
  }
  // Unpack the data to match the HomePageProps interface
  const homePageData = {
    data: {
      heroHeading: data?.heroHeading ?? '',
      heroSubheading: data?.heroSubheading ?? '',
      heroParagraph: data?.heroParagraph ?? '',
      heroImage: data?.heroImage ?? '',
      featureTopHeading: data?.featureTopHeading ?? '',
      featureTopSubheading: data?.featureTopSubheading ?? '',
      featureHeading1: data?.featureHeading1 ?? '',
      featureSubheading1: data?.featureSubheading1 ?? '',
      featureHeading2: data?.featureHeading2 ?? '',
      featureSubheading2: data?.featureSubheading2 ?? '',
      featureHeading3: data?.featureHeading3 ?? '',
      featureSubheading3: data?.featureSubheading3 ?? '',
      featureImage: data?.featureImage ?? '',
      testimonialQuote1: data?.testimonialQuote1 ?? '',
      testimonialName1: data?.testimonialName1 ?? '',
      testimonialTitle1: data?.testimonialTitle1 ?? '',
      testimonialLocation1: data?.testimonialLocation1 ?? '',
      testimonialImage1: data?.testimonialImage1 ?? '',
      testimonialQuote2: data?.testimonialQuote2 ?? '',
      testimonialName2: data?.testimonialName2 ?? '',
      testimonialTitle2: data?.testimonialTitle2 ?? '',
      testimonialLocation2: data?.testimonialLocation2 ?? '',
      testimonialImage2: data?.testimonialImage2 ?? '',
      leadFormHeading: data?.leadFormHeading ?? '',
      leadFormParagraph: data?.leadFormParagraph ?? '',
      leadFormButton: data?.leadFormButton ?? '',
      companyName: data?.companyName ?? '',
      footerText: data?.footerText ?? '',
      logoImage: data?.logoImage ?? '',
      logoTagline: data?.logoTagline ?? '',
    }
  }

  return (
    <div className="App">
      {/* Add GTMHead component as high in the head of the page as possible */}
      <GTMHead gtmId={process.env.REACT_APP_GTM_ID ?? ''} />
      <header className="App-header">
        {/* Add GTMBody component after Opening Body Tag AKA top of Header */}
        <GTMBody gtmId={process.env.REACT_APP_GTM_ID ?? ''} />
        <Router>
          <Routes>
            <Route path="/" element={<HomePage {...homePageData} />} />
            <Route path="/thank-you" element={<ThankYouPage  {...thankYouData} />} />
          </Routes>
        </Router>
      </header>
    </div>
  );
};

export default App;