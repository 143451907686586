import HeroImage from './HeroImage';
import HeroButton from './HeroButton';
import { Helmet } from 'react-helmet';

//Interfaces
interface ThankYouPageProps {
    data: {
        heroHeading: string; 
        heroParagraph: string;
        heroButton: string;
        heroImageUrl: string;
        outBoundLink: string;
    }
    // Add other properties as needed
}

const ThankYouPage: React.FC<ThankYouPageProps> = ({ data }) => {
    // No need to reassign data to heroHeading if we're not transforming it
    // If data is an empty string, null, or undefined, 'Default Heading' will be displayed
    return (
        <div className="pageBG">
            <Helmet>
                <title>Thank You! Ready to Level Up?</title>
                {/* Meta Tags */}
                <meta name="description" content="Thank you for joining, a member of the Postx3 team will reach out shortly." />
                <meta name="robots" content="noindex" />
                <meta name="keywords" content="Postx3, Postx3.com, Real Estate AI, Postx3 AI, Postx3 Real Estate, Postx3 Marketing, Postx3 Marketing Agency, Postx3 Digital Marketing, Postx3 Digital Marketing Agency" />
                <meta name="author" content="Postx3" />
                
                {/* Open Graph Data */}
                <meta property="og:title" content="Thank You! Ready to Level Up?" />
                <meta property="og:description" content="Thank you for joining, a member of the Postx3 team will reach out shortly." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://go.postx3.com/thank-you" />
                <meta property="og:image" content="https://postx3.com/wp-content/uploads/2024/01/features-2.png" />
            
                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@postx3" />
                <meta name="twitter:title" content="Thank You! Ready to Level Up?" />
                <meta name="twitter:description" content="Thank you for joining, a member of the Postx3 team will reach out shortly." />
                <meta name="twitter:image" content="https://postx3.com/wp-content/uploads/2024/01/features-2.png" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Postx3",
                        "url": "https://postx3.com",
                        "logo": "https://postx3.com/wp-content/uploads/2024/01/features-2.png",
                        "sameAs": [
                            "https://www.instagram.com/postx3ai",
                            "https://www.linkedin.com/company/postx3",
                            "https://twitter.com/postx3_team",
                            "https://tiktok.com/@postx3",
                        ]
                    }
                    `}
                </script>
            </Helmet>
            <div className="topSection">
                <div className="thankYouContainer">
                    <h1 className="heroHeading">{data.heroHeading || "We're on it!"}</h1>
                    <p className="heroParagraph">{data.heroParagraph || 'thank you for joining, a member of the Postx3 team will reach out shortly.'}</p>
                    {/* <a href={data.outBoundLink}><button className="calendarCTAButton">{data.heroButton}</button></a> */}
                    <a href={data.outBoundLink}>
                        <div className="calendarCTAButton">
                            <HeroButton data={data.heroButton} link={data.outBoundLink} />
                        </div>
                    </a>
                    <div className="heroImageContainer">
                        <HeroImage data={data.heroImageUrl} />
                    </div>
                    <br />
                </div>
            </div>
        </div>
    );
}

export default ThankYouPage;