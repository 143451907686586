import React from 'react';


interface HeroButtonProps {
    data: string;
    link: string;
}

const HeroButton: React.FC<HeroButtonProps> = ({ data, link }) => {
    
    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <button className="modalOpenButton">{data} </button>
        </a>
    );
};

export default HeroButton;