
import React from 'react';
import ModalOpenButton from './ModalOpenButton';

interface featureListProps {
  data: {
    featureHeading: string;
    featureSubheading: string;
    leadFormHeading: string;
    leadFormParagraph: string;
    leadFormButton: string;
    featureList: { title: string; description: string }[];
  };
}

const FeatureList: React.FC<featureListProps> = ({ data }) => {
  const modalOpenButtonData = {
    modalOpenButton: 'Get Started',
    leadFormHeading: data.leadFormHeading,
    leadFormParagraph: data.leadFormParagraph,
    leadFormButton: data.leadFormButton,
  };
  return (
    <div className='featureDetails'>
      <ModalOpenButton data={modalOpenButtonData} />
      <h3 className='featureSubheading'>{data.featureSubheading}</h3>
      <div className="featureList">
        <ul>
          {data.featureList.map((feature, index) => (
            <li key={index} className="featureItem">
              <img src="https://analytics.nyc3.cdn.digitaloceanspaces.com/Postx3/microsite-optin-lp-assets/postx3-pinIcon.svg" alt="feature" className="featureIcon" />
              <div className="featureText">
                <h5>{feature.title}</h5>
                <p>{feature.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FeatureList;