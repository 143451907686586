import React, { useState } from 'react';
import ModalForm from './ModalForm';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface ModalOpenButtonProps {
    data: {
        modalOpenButton: string;
        leadFormHeading: string;
        leadFormParagraph: string;
        leadFormButton: string;

    }
}

const ModalOpenButton: React.FC<ModalOpenButtonProps> = ({ data }) => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();


    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleSubmitForm = async (formData: {
        name: string;
        email: string;
        phoneNumber: string;
    }) => {
        await axios.post('https://micrositeapi.postx3.com/leads', {
            name: formData.name,
            email: formData.email,
            phoneNumber: formData.phoneNumber
        });
        handleCloseModal();
        navigate('/thank-you');


    };

    return (
        <div>
            <div className='ctaButtonRow'>
                <button className="modalOpenButton" onClick={handleOpenModal}>{data.modalOpenButton}</button>
            </div>
            <ModalForm show={showModal}
                onClose={handleCloseModal}
                onSubmit={handleSubmitForm}
                leadFormHeading={data.leadFormHeading}
                leadFormParagraph={data.leadFormParagraph}
                leadFormButton={data.leadFormButton}
            />
        </div>
    );
};
export default ModalOpenButton;
