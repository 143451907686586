import React from 'react';

interface TestimonialProps {
    data: {
        name: string;
        title: string;
        location: string;
        quote: string;
        image: string;
     };
};

const Testimonial: React.FC<TestimonialProps> = ({ data }) => {
    return (
        <div className="testimonial">
            <div className='testimonialQuote'>"{data.quote}"</div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <img className='testimonialImage' src={data.image} alt={data.name} />
                <div className="testimonialInfo">
                    <div className='testimonialName'>{data.name}</div>
                    <div className='testimonialTitle'>{data.title}</div>
                    <div className='testimonialLocation'>{data.location}</div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;


// import React from 'react';

// interface TestimonialProps {
//     data: {
//         name: string;
//         title: string;
//         location: string;
//         quote: string;
//         image: string;
//      };
// };

// const Testimonial: React.FC<TestimonialProps> = ({ data }) => {
//     return (
//         <div className="testimonial">
//             <img className='testimonialImage' src={data.image} alt={data.name} />
//             <ul>
//                 <li className='testimonialQuote'>{data.quote}</li>
//                 <li className='testimonialName'>{data.name}</li>
//                 <li className='testimonialTitle'>{data.title}</li>
//                 <li className='testimonialLocation'>{data.location}</li>
//             </ul>
//         </div>
//     );
// };

// export default Testimonial;