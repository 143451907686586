interface HeroImageProps {
    data: string;
}

const heroImage: React.FC<HeroImageProps> = ({ data }) => {
    // const heroImage =
    //     {
    //         src: data,
    //         alt: "Placeholder hero image",
    //         title: "Placeholder hero image",
    //     };
    return (
        <img className="heroImage" src={data} alt="thank you." title="thank you, our team will reach out shortly." />
    );
}

export default heroImage;