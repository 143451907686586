import React from 'react';
// import HeroHeading from './HeroHeading';
import HeroSubheading from './HeroSubheading';
// import HeroButton from './HeroButton';
import HeroParagraph from './HeroParagraph';
// import HeroVideo from './HeroVideo';
import ModalOpenButton from './ModalOpenButton';
import HeroImage from './HeroImage';
import FeatureList from './FeatureList';
import Testimonial from './Testimonial';


import { Helmet } from 'react-helmet';

interface HomePageProps {
  data: {
    heroHeading: string;
    heroSubheading: string;
    heroParagraph: string;
    heroImage: string;
    featureTopHeading: string;
    featureTopSubheading: string;
    featureHeading1: string;
    featureSubheading1: string;
    featureHeading2: string;
    featureSubheading2: string;
    featureHeading3: string;
    featureSubheading3: string;
    featureImage: string;
    testimonialQuote1: string;
    testimonialName1: string;
    testimonialTitle1: string;
    testimonialLocation1: string;
    testimonialImage1: string;
    testimonialQuote2: string;
    testimonialName2: string;
    testimonialTitle2: string;
    testimonialLocation2: string;
    testimonialImage2: string;
    leadFormHeading: string;
    leadFormParagraph: string;
    leadFormButton: string;
    companyName: string;
    footerText: string;
    logoImage: string;
    logoTagline: string;
  }
}

const HomePage: React.FC<HomePageProps> = ({ data }) => {
  const featureListData = {
    modalOpenButton: 'Get Started',
    featureHeading: data.featureTopHeading ?? '',
    featureSubheading: data.featureTopSubheading ?? '',
    leadFormHeading: data.leadFormHeading ?? 'Contact Us',
    leadFormParagraph: data.leadFormParagraph ?? 'Please fill out the form below and we will get back to you as soon as possible.',
    leadFormButton: data.leadFormButton ?? 'Submit',
    featureList: [
      {
        title: data.featureHeading1 ?? '',
        description: data.featureSubheading1 ?? '',
      },
      {
        title: data.featureHeading2 ?? '',
        description: data.featureSubheading2 ?? '',
      },
      {
        title: data.featureHeading3 ?? '',
        description: data.featureSubheading3 ?? '',
      },
    ]
  };

  const modalOpenButtonData = {
    modalOpenButton: 'Get Started',
    leadFormHeading: data.leadFormHeading ?? 'Contact Us',
    leadFormParagraph: data.leadFormParagraph ?? 'Please fill out the form below and we will get back to you as soon as possible.',
    leadFormButton: data.leadFormButton ?? 'Submit',
  };

  return (
    <>
    <Helmet>
      <title>Postx3: Advanced Lead Generation for Real Estate Agents</title>
      {/* Meta Tags */}
      <meta name="description" content="Postx3 is a real estate marketing agency that uses AI to generate leads for real estate agents." />
      <meta name="robots" content="noindex" />
      <meta name="keywords" content="Postx3, Postx3.com, Real Estate AI, Postx3 AI, Postx3 Real Estate, Postx3 Marketing, Postx3 Marketing Agency, Postx3 Digital Marketing, Postx3 Digital Marketing Agency" />
      <meta name="author" content="Postx3" />
      
      {/* Open Graph Data */}
      <meta property="og:title" content="Postx3: Advanced Lead Generation for Real Estate Agents" />
      <meta property="og:description" content="Postx3 is a real estate marketing agency that uses AI to generate leads for real estate agents." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://go.postx3.com/" />
      <meta property="og:image" content="https://postx3.com/wp-content/uploads/2024/01/features-2.png" />
  
      {/* Twitter Meta Tags */} 
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@postx3" />
      <meta name="twitter:title" content="Postx3: Advanced Lead Generation for Real Estate Agents" />
      <meta name="twitter:description" content="Postx3 is a real estate marketing agency that uses AI to generate leads for real estate agents." />
      <meta name="twitter:image" content="https://postx3.com/wp-content/uploads/2024/01/features-2.png" />

      {/* JSON-LD structured data */}
      <script type="application/ld+json">
          {`
          {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Postx3",
              "url": "https://postx3.com",
              "logo": "https://postx3.com/wp-content/uploads/2024/01/features-2.png",
              "sameAs": [
                  "https://www.instagram.com/postx3ai",
                  "https://www.linkedin.com/company/postx3",
                  "https://twitter.com/postx3_team",
                  "https://tiktok.com/@postx3",
              ]
          }
          `}
      </script>
    </Helmet>
    
    <link rel="stylesheet" href='https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap'/>
    <link rel="stylesheet" href='https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&display=swap'/>    <div className="heroContainer">
      <div className="pageBG">
        <div className="topSection">
          <HeroSubheading data={data.heroSubheading ?? ''} />
          <HeroParagraph data={data.heroParagraph ?? ''} />
          <ModalOpenButton data={modalOpenButtonData} />
        </div>

          <div className="heroImageContainer">
            <HeroImage data={data.heroImage ?? ''} />
          </div>

          <div className="featuresSection">

            <FeatureList data={featureListData} />
            <ModalOpenButton data={modalOpenButtonData} />
            <div className="featureImageContainer">
              <HeroImage data={data.featureImage ?? ''} />
            </div>
          </div>

          <div className="testimonialSection">
            <Testimonial data={{
              name: data.testimonialName1 ?? '',
              title: data.testimonialTitle1 ?? '',
              location: data.testimonialLocation1 ?? '',
              quote: data.testimonialQuote1 ?? '',
              image: data.testimonialImage1 ?? '',
            }} />
            <Testimonial data={{
              name: data.testimonialName2 ?? '',
              title: data.testimonialTitle2 ?? '',
              location: data.testimonialLocation2 ?? '',
              quote: data.testimonialQuote2 ?? '',
              image: data.testimonialImage2 ?? '',
            }} />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;