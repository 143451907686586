interface HeroParagraphProps {
    data: string;
}
const heroParagraph: React.FC<HeroParagraphProps> = ({ data }) => {
    return (
        <div className="heroParagraph">
        <p>
            {data || 'Default Paragraph'}
        </p>
        </div>
    );
}

export default heroParagraph;