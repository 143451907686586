import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface Props {
    show: boolean;
    onClose: () => void;
    onSubmit: (formData: {
        name: string;
        email: string;
        phoneNumber: string; // Now optional
    }) => void;
    leadFormHeading: string;
    leadFormParagraph: string;
    leadFormButton: string;
}

// Validation schema
const schema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required").email("Email is invalid"),
    phoneNumber: yup.string().required("Phone number is required").matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, "Phone number is not valid")
}).required();

const ModalForm: React.FC<Props> = ({
    show,
    onClose,
    onSubmit,
    leadFormHeading,
    leadFormParagraph,
    leadFormButton
}) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitForm: SubmitHandler<{ name: string; email: string; phoneNumber: string }> = (data: any) => {
        // onSubmit(data);
        onSubmit({
          ...data
          // phoneNumber: data.phoneNumber ?? '' // Set phoneNumber to empty string if it's undefined
        })
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modalFormContainer">
            <div className="modalFormContent">
                <button className='modalCloseButton' onClick={onClose}>x</button>
                <h2>{leadFormHeading}</h2>
                <p>{leadFormParagraph}</p>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <label>
                        Name*
                        <input type="text" {...register('name')} />
                        <p>{errors.name?.message}</p>
                    </label>
                    <br />
                    <label>
                        Email*
                        <input type="email" {...register('email')} />
                        <p>{errors.email?.message}</p>
                    </label>
                    <br />
                    <label>
                        Phone*
                        <input type="tel" {...register('phoneNumber')} />
                        <p>{errors.phoneNumber?.message}</p>
                    </label>
                    <br />
                    <button className="modalFormButton" type="submit">{leadFormButton}</button>
                </form>
            </div>
        </div>
    );
}

export default ModalForm;


// import React from 'react';
// import { useForm, SubmitHandler } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';

// interface Props {
//     show: boolean;
//     onClose: () => void;
//     onSubmit: (formData: {
//         name: string;
//         email: string;
//         phoneNumber: string;
//     }) => void;
//     leadFormHeading: string;
//     leadFormParagraph: string;
//     leadFormButton: string;
// }

// const ModalForm: React.FC<Props> = ({ show, onClose, onSubmit, 
//   leadFormHeading, leadFormParagraph, leadFormButton }) => {
//     const [formData, setFormData] = React.useState({
//         name: '',
//         email: '',
//         phoneNumber: '',
//     });

    
//     if (!show) {
//         return null;
//     }

//     return (
//         <div className="modalFormContainer">
//             <div className="modalFormContent">
//             <button className='modalCloseButton' onClick={onClose}>x</button>
//             <h2>{leadFormHeading}</h2>
//             <p>{leadFormParagraph}</p>
//         <form
//           onSubmit={e => {
//             e.preventDefault();
//             onSubmit(formData);
//           }}
//         >
//             <label>
//             Name*
//             <br />
//             <input
//               type="text"
//               value={formData.name}
//               onChange={e => setFormData({ ...formData, name: e.target.value })}
//             />
//           </label>
//           <br />
//           <label>
//             Email*
//             <br />
//             <input
//               type="email"
//               value={formData.email}
//               onChange={e => setFormData({ ...formData, email: e.target.value })}
//             />
//           </label>
//           <br />
//           <label>
//             Phone*
//             <br />
//             <input
//               type="tel"
//               value={formData.phoneNumber}
//               onChange={e => setFormData({ ...formData, phoneNumber: e.target.value })}
//             />
//           </label>
//           <br />
//           <button className="modalFormButton" type="submit">{leadFormButton}</button>
//         </form>
//           </div>
//       </div>
//     )
// }

// export default ModalForm;