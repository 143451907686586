import React from 'react';

interface GTMBodyProps {
  gtmId: string;
}

const GTMBody: React.FC<GTMBodyProps> = ({ gtmId }) => {
  // Create the iframe snippet using the gtmId
  const iframeHtml = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;

  return (
    // Using `dangerouslySetInnerHTML` to insert the raw HTML
    <noscript dangerouslySetInnerHTML={{ __html: iframeHtml }} />
  );
};

export default GTMBody;
